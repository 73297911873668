<template>
    <div class="warning">
        <a-form :form="form" layout="inline" class="warning-grid">
            <a-form-item label="公司简称">
                <a-select
                    placeholder="请选择"
                    v-decorator="['companyName']"
                    show-search
                    :filter-option="selectFilterOption"
                >
                    <a-select-option
                        v-for="(item, index) in companyList"
                        :key="index"
                        :value="item.name"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="电站名称">
                <a-input
                    placeholder="请输入"
                    autocomplete="new-password"
                    v-decorator="['powerStationName']"
                ></a-input>
            </a-form-item>
            <a-form-item label="stationCode">
                <a-input
                    placeholder="请输入"
                    autocomplete="new-password"
                    v-decorator="['stationCode']"
                ></a-input>
            </a-form-item>
            <a-form-item label="异常电站">
                <a-select
                    placeholder="请选择"
                    v-decorator="['status', { initialValue: -1 }]"
                >
                    <a-select-option
                        v-for="(item, index) in statusList"
                        :key="index"
                        :value="item.value"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item :colon="false">
                <span slot="label"></span>
                <a-button
                    type="primary"
                    class="btn-primary"
                    @click="handleSearch"
                >
                    查询
                </a-button>
                <a-button @click="handleReset">重置</a-button>
            </a-form-item>
        </a-form>
        <a-table
            rowKey="powerStationId"
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :pagination="pagination"
            :scroll="{ x: 1800 }"
            @change="handleTable"
        >
            <!-- 储能功率 -->
            <span slot="power" slot-scope="text">
                {{ text | unitFilters("kw") }} kW
            </span>
            <!-- 策略功率 -->
            <span slot="strategyPower" slot-scope="text">
                {{ text | unitFilters("kw") }} kW
            </span>
            <!-- 功率状态（与策略是否相符） -->
            <span
                slot="status"
                slot-scope="text"
                :class="{ 'green-text': 1, 'red-text': text == 0 }"
            >
                {{ text == 0 ? "不相符" : text == 1 ? "相符" : "-" }}
            </span>
            <!-- 逆流和超需量 -->
            <span
                slot="dcLimit"
                slot-scope="text, record"
                :class="{
                    'red-text': record.gridStatus == 1,
                    'blue-text': record.gridStatus == 2,
                }"
            >
                <span>{{ record | limitFilters }}</span>
            </span>
            <!-- 策略配置 -->
            <span
                slot="strategyStatus"
                slot-scope="text"
                :class="{ 'green-text': 1, 'red-text': text == 0 }"
            >
                {{ text == 0 ? "未配置" : text == 1 ? "配置" : "-" }}
            </span>
            <!-- 充放电量环比（日|周） -->
            <span slot="chargeChainDay" slot-scope="text, record">
                <span :class="{ 'red-text': Math.abs(text) > 0.15 }">
                    {{ text | percentFilters | unitFilters }}%
                </span>
                /
                <span
                    :class="{
                        'red-text': Math.abs(record.chargeChainWeek) > 0.15,
                    }"
                >
                    {{ record.chargeChainWeek | percentFilters | unitFilters }}%
                </span>
            </span>
            <span slot="dischargeChainDay" slot-scope="text, record">
                <span :class="{ 'red-text': Math.abs(text) > 0.15 }">
                    {{ text | percentFilters | unitFilters }}%
                </span>
                /
                <span
                    :class="{
                        'red-text': Math.abs(record.dischargeChainWeek) > 0.15,
                    }"
                >
                    {{
                        record.dischargeChainWeek
                            | percentFilters
                            | unitFilters
                    }}%
                </span>
            </span>
            <!-- 电价是否配置 -->
            <div slot="priceThisMonth" slot-scope="text, record">
                <span :class="{ 'green-text': 1, 'red-text': !text }">
                    {{ !text ? "未配" : "已配" }}
                </span>
                /
                <span
                    :class="{
                        'green-text': 1,
                        'red-text': !record.priceNestMonth,
                    }"
                >
                    {{ !record.priceNestMonth ? "未配" : "已配" }}
                </span>
            </div>
            <!-- 是否自动监视控制 -->
            <a-switch
                slot="monitorStatus"
                slot-scope="text, record"
                checked-children="是"
                un-checked-children="否"
                :checked="!!text"
                :loading="record._loading"
                @change="switchMonitorChange(record, $event)"
            ></a-switch>
            <!-- 电站标签 -->
            <div
                class="description-tag"
                slot="description"
                slot-scope="text, record"
            >
                <div class="description-tag-item" v-show="record.description">
                    {{ record.description | tagFilters }}
                </div>
                <a-icon type="edit" @click="showTagModal(record)" />
            </div>
        </a-table>

        <a-modal
            :title="tagModal.title"
            :visible="tagModal.visible"
            @ok="handleTagOk"
            @cancel="handleTagCancel"
        >
            <a-select
                style="width: 240px"
                placeholder="请选择"
                v-model="tagModal.current"
            >
                <a-select-option
                    v-for="tag in tagModal.enum"
                    :key="tag.label"
                    :value="tag.value"
                >
                    {{ tag.label }}
                </a-select-option>
            </a-select>
        </a-modal>
    </div>
</template>

<script>
import option from "./option.js";
export default option;
</script>

<style scoped lang="less">
.warning {
    &-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
        margin-bottom: 16px;
        /deep/ .ant-form-item {
            display: flex;
            margin-right: 0;
            &-label {
                flex-shrink: 0;
            }
            &-control-wrapper {
                flex: 1;
                .btn-primary {
                    margin-left: -8px;
                    margin-right: 8px;
                }
            }
        }
    }
    .green-text {
        color: green;
    }
    .red-text {
        color: red;
    }
    .blue-text {
        color: rgba(214, 32, 214, 1);
    }
    .description-tag {
        .anticon {
            cursor: pointer;
        }
        &-item {
            display: inline-block;
            padding: 0 4px;
            margin-right: 8px;
            border-radius: 4px;
            background-color: #13c2c2;
            color: #fff;
        }
    }
}
</style>
