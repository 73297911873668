import * as api from "@/api/index";
export default {
  name: "SiteWarning",
  filters: {
    percentFilters(val) {
      return val * 100;
    },
    // 逆流和超需量状态判断
    limitFilters(record) {
      const { gridStatus, gridPower, rpLimit, dcLimit } = record;
      if (gridStatus == 0) return "无";
      let left = parseFloat(gridPower / 1000).toFixed(0),
        rp_right = parseFloat(rpLimit / 1000).toFixed(0),
        dc_right = parseFloat(dcLimit / 1000).toFixed(0);
      if (gridStatus == 2) return `逆流(${left}/${rp_right})kW`;
      if (gridStatus == 1) return `超需(${left}/${dc_right})kW`;
    },
    // 电站标签
    tagFilters(val) {
      if (val === 1) return "有光伏";
      if (val === 2) return "EMS被控";
      if (val === 3) return "有光伏&EMS被控";
    },
  },
  data() {
    return {
      api,
      // 查询
      form: this.$form.createForm(this),
      companyList: [],
      statusList: [
        { value: -1, name: "全部" },
        { value: 0, name: "不相符" },
        { value: 1, name: "相符" },
        { value: 2, name: "正常" },
        { value: 3, name: "超需" },
        { value: 4, name: "逆流" },
      ],
      // 表格
      columns: [
        { title: "站点名称", dataIndex: "powerStationName" },
        { title: "公司简称", dataIndex: "companyName" },
        {
          title: "SOC",
          dataIndex: "soc",
          customRender: (val) => {
            return `${val} %`;
          },
        },
        {
          title: "储能功率",
          dataIndex: "power",
          scopedSlots: { customRender: "power" },
        },
        {
          title: "策略功率",
          dataIndex: "strategyPower",
          scopedSlots: { customRender: "strategyPower" },
        },
        {
          title: "功率状态(与策略相比)",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "逆流和超需",
          dataIndex: "dcLimit",
          scopedSlots: { customRender: "dcLimit" },
        },
        {
          title: "策略配置",
          dataIndex: "strategyStatus",
          scopedSlots: { customRender: "strategyStatus" },
        },
        {
          title: "充电量环比昨日/上周",
          dataIndex: "chargeChainDay",
          scopedSlots: { customRender: "chargeChainDay" },
        },
        {
          title: "放电量环比昨日/上周",
          dataIndex: "dischargeChainDay",
          scopedSlots: { customRender: "dischargeChainDay" },
        },
        {
          title: "当月/下月电价配置",
          dataIndex: "priceThisMonth",
          scopedSlots: { customRender: "priceThisMonth" },
        },
        {
          title: "电价(尖峰平谷)",
          dataIndex: "price",
          customRender: (text) => (!text ? "-" : text),
        },
        {
          title: "纳入自动监视",
          dataIndex: "monitorStatus",
          scopedSlots: {
            customRender: "monitorStatus",
          },
          width: 110,
          fixed: "right",
        },
        {
          title: "电站标签",
          dataIndex: "description",
          scopedSlots: {
            customRender: "description",
          },
          fixed: "right",
        },
      ],
      dataList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        total: 0,
        showTotal: (total) => `电站总数：${total}`,
      },
      loading: false,
      // modal
      tagModal: {
        title: "电站标签编辑",
        visible: false,
        record: null,
        enum: [
          { label: "正常电站", value: 0 },
          { label: "有光伏", value: 1 },
          { label: "EMS被控", value: 2 },
          { label: "有光伏&EMS被控", value: 3 },
        ],
        // 当前选择
        current: undefined,
      },
    };
  },
  mounted() {
    this.getCompanyList();
  },
  methods: {
    // 公司列表
    getCompanyList() {
      this.api.companyList().then(({ data }) => {
        this.companyList = data;
        this.handleSearch();
      });
    },
    // 搜索过滤公司
    selectFilterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 表单查询结果
    getTableList() {
      this.loading = true;
      const values = this.form.getFieldsValue();
      if (values.status === -1) values.status = undefined;
      if (values.status >= 2) {
        values.limit = values.status - 2;
        Reflect.deleteProperty(values, "status");
      }
      this.api
        .getInspectionPage({
          ...values,
          current: this.pagination.current,
          pageSize: this.pagination.pageSize,
        })
        .then(({ data }) => {
          this.dataList = data?.list ?? [];
          this.pagination.total = data?.total ?? 0;
        })
        .finally(() => (this.loading = false));
    },
    // 按钮：搜索
    handleSearch() {
      this.pagination.current = 1;
      this.getTableList();
    },
    // 按钮：重置
    handleReset() {
      this.form.resetFields();
      this.pagination.pageSize = 10;
      this.handleSearch();
    },
    // 表格：分页
    handleTable(p) {
      this.pagination.current = p.current;
      this.pagination.pageSize = p.pageSize;
      this.getTableList();
    },
    // 表格：更新监控状态
    switchMonitorChange(record, checked) {
      this.$set(record, "_loading", true);
      this.api
        .updateInspectionRecord({
          monitorStatus: record.monitorStatus ? 0 : 1,
          description: record.description,
          powerStationId: record.powerStationId,
        })
        .then(() => {
          this.$message.success("操作成功");
          record.monitorStatus = checked;
        })
        .finally(() => this.$set(record, "_loading", false));
    },
    // 电站标签编辑
    showTagModal(record) {
      this.tagModal.record = record;
      if (!record.description && record.description !== 0) {
        this.tagModal.current = undefined;
      } else {
        this.tagModal.current = record.description;
      }
      this.tagModal.visible = true;
    },
    handleTagOk() {
      const { record, current } = this.tagModal;
      this.api
        .updateInspectionRecord({
          description: current,
          monitorStatus: record.monitorStatus,
          powerStationId: record?.powerStationId,
        })
        .then(() => {
          this.$message.success("操作成功");
          record.description = current;
          this.handleTagCancel();
        });
    },
    handleTagCancel() {
      this.tagModal.record = null;
      this.tagModal.visible = false;
    },
  },
};
